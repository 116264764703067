import React from 'react'
import { NavLink } from 'react-router-dom'

interface MenuLinkProps {
  children: React.ReactNode
  to: string
}

function MenuLink({ children, to }: MenuLinkProps) {
  return (
    <NavLink
      activeClassName="bg-surface-subtle text-color-strong"
      to={to}
      className="group flex rounded-md p-sm text-sm font-medium text-color-subtle transition hover:bg-surface-strong"
    >
      {children}
    </NavLink>
  )
}

export { MenuLink }
