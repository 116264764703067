import React from 'react'
import {Route, Switch} from 'react-router-dom'
import {NotificationSettings} from './screens/NotificationSettings'
import {NOTIFICATIONS_ROUTES} from '@src/modules/Notifications/constants'
import {MenuLink} from '@src/modules/Notifications/components/MenuLink'
import {Notifications} from '@src/modules/Notifications/router/screens/Notifications'

function NotificationsRoutes() {
  return (
    <div className="mx-auto flex max-w-screen-xl flex-col justify-center sm:flex-row">
      <nav
        className="sticky top-0 z-sticky sm:ml-base bg-background pl-sm sm:pl-0 py-sm sm:py-0 sm:pt-base sm:mr-xl sm:inline sm:w-52 sm:self-start">
        <ul className="flex space-x-sm sm:block sm:space-x-0 sm:space-y-sm">
          <MenuLink to={NOTIFICATIONS_ROUTES.__ROOT__}>Notifications</MenuLink>
          <MenuLink to={NOTIFICATIONS_ROUTES.SETTINGS}>Preferences</MenuLink>
        </ul>
      </nav>

      <div className="mr-xxl mt-base w-full max-w-screen-lg">
        <Switch>
          <Route
            component={NotificationSettings}
            path={NOTIFICATIONS_ROUTES.SETTINGS}
          />

          <Route component={Notifications}/>
        </Switch>
      </div>
    </div>
  )
}

export {NotificationsRoutes}
