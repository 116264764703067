import React from 'react'
import {
  NoResults,
  RPCs,
  RPCDocs,
  useCurrentUser,
  usePaginationState,
  UserAuthSessionsTable,
  useRPC,
  useRPCContext,
} from '@therms/react-modules'
import { Loading } from '@therms/atalaya'

function AuthSessionsIndex() {
  const {
    recordsPerPage,
    setRecordsPerPage,
    setSkippedRecords,
    skippedRecords,
  } = usePaginationState('profileuserauthtable', [])
  const currentUser = useCurrentUser()

  const { rpcService } = useRPCContext()

  const { call, data, isCalling } = useRPC<
    RPCDocs.Users.GetUserAuthSessionsList.v1.Args,
    RPCDocs.Users.GetUserAuthSessionsList.v1.Data
  >(RPCs.Users.GetUserAuthSessionsList.v1, {
    pagination: {
      limit: recordsPerPage,
      skip: skippedRecords,
    },
    userId: currentUser.id,
  })

  const handleKillAuthSessions = (authSessionPublicIds: string[]) =>
    rpcService.Users.KillUserAuthSessions.v1
      .call({
        authSessionPublicIds,
      })
      .then(() => {
        call()
      })

  return (
    <div className="relative px-base">
      {isCalling && <Loading message="Loading" overlay size="xl" />}

      {data?.total ? (
        <UserAuthSessionsTable
          data={data?.userAuthSessions ?? []}
          onKillSelectedAuthSessions={handleKillAuthSessions}
          recordsPerPage={recordsPerPage}
          setRecordsPerPage={setRecordsPerPage}
          setSkippedRecords={setSkippedRecords}
          skippedRecords={skippedRecords}
          totalRecords={data?.total}
        />
      ) : (
        <NoResults text="No Sessions" />
      )}
    </div>
  )
}

export { AuthSessionsIndex }
