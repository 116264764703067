import React from 'react'
import {Route, Switch} from "react-router-dom"
import { InformationIndex } from './screens'

function InformationRoutes() {
  return (
    <Switch>
      <Route component={InformationIndex} />
    </Switch>
  )
}

export { InformationRoutes }
