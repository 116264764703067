import React from 'react'
import { MailScreen } from '@therms/react-modules'

function MailIndex() {
  return (
    <div className="mx-base">
      <MailScreen />
    </div>
  )
}

export { MailIndex }
