// @ts-nocheck
/* eslint-disable import/no-import-module-exports */
import React from 'react'
import ReactDOM from 'react-dom'
import * as Sentry from '@sentry/react'
// must be imported before any other UI libs/components
import './index.css'
import { App } from '@src/App'
import { isProd } from '@src/utils/is-prod'

Sentry.init({
  environment: isProd ? 'production' : 'sandbox',
  ignoreErrors: ["ResizeObserver"],
  dsn: 'https://0d5cae6b186745fba2fcad541c29d302@o128985.ingest.sentry.io/6519066',
  integrations: [Sentry.browserTracingIntegration()],
  enabled: !module.hot,
  tracesSampleRate: 0.2,
})

ReactDOM.render(<App />, document.querySelector('#root'))

// dev env HMR
if (module?.hot) {
  module.hot.accept()
}
