import { Icon } from '@therms/react-modules'
import React from 'react'

interface AppCardProps {
  description: string
  icon?: string
  link: string
  name: string
}

function AppCard({ description, icon, link, name }: AppCardProps) {
  return (
    <a
      className="group flex items-center space-x-xs rounded bg-surface p-base transition-colors hover:bg-surface-strong focus:bg-surface-strong active:bg-surface-subtle"
      href={link}
    >
      {!!icon && (
        <div>
          <Icon name={icon} size="xl" color="main" />
        </div>
      )}
      <div className="overflow-hidden">
        <div className="font-medium text-color-strong">{name}</div>
        <div className="truncate text-sm">{description}</div>
      </div>
    </a>
  )
}

export { AppCard }
