import React, { useCallback, useEffect, useState } from 'react'
import {
  useLocation,
  matchPath,
  useHistory,
  Redirect,
  Switch,
  Route,
} from 'react-router-dom'
import { AppLayoutContainer } from './modules/Layout/shared/AppLayoutContainer'
import { INFORMATION_ROUTES } from '@src/modules/Information/constants'
import { InformationRoutes } from '@src/modules/Information/router/Routes'
import { Button, HorizontalScroll, Tabs, toastManager } from '@therms/atalaya'
import { PERMISSIONS_ROUTES } from '@src/modules/Permissions/constants'
import { PermissionsRoutes } from '@src/modules/Permissions/router/Routes'
import { NOTIFICATIONS_ROUTES } from '@src/modules/Notifications/constants'
import { NotificationsRoutes } from '@src/modules/Notifications/router/Routes'
import {
  Alerts,
  useAppList,
  useCurrentUser,
  WEB_APPS_LIST,
} from '@therms/react-modules'
import { AppCard } from './components/AppCard'
import { AUDIT_LOGS_ROUTES } from '@src/modules/AuditLogs/constants'
import { AuditLogsRoutes } from '@src/modules/AuditLogs/router/Routes'
import { AUTH_SESSIONS_ROUTES } from '@src/modules/AuthSessions/constants'
import { AuthSessionsRoutes } from '@src/modules/AuthSessions/router/Routes'
import { isProd } from '@src/utils/is-prod'
import { MAIL_ROUTES } from '@src/modules/Mail/constants'
import { MailRoutes } from '@src/modules/Mail/router/Routes'

const KEYS = {
  AUDIT_LOGS: 'AUDIT_LOGS',
  AUTH_SESSIONS: 'AUTH_SESSIONS',
  INFORMATION: 'INFORMATION',
  MAIL: 'MAIL',
  NOTIFICATIONS: 'NOTIFICATIONS',
  PERMISSIONS: 'PERMISSIONS',
}

const PAGE_NAMES = {
  AUDIT_LOGS: 'Activity History',
  AUTH_SESSIONS: 'Devices',
  INFORMATION: 'Information',
  MAIL: 'Mail',
  NOTIFICATIONS: 'Notifications',
  PERMISSIONS: 'Access & Permissions',
}

const ROUTES = {
  AUDIT_LOGS: AUDIT_LOGS_ROUTES.__ROOT__,
  AUTH_SESSIONS: AUTH_SESSIONS_ROUTES.__ROOT__,
  INFORMATION: INFORMATION_ROUTES.__ROOT__,
  MAIL: MAIL_ROUTES.__ROOT__,
  NOTIFICATIONS: NOTIFICATIONS_ROUTES.__ROOT__,
  PERMISSIONS: PERMISSIONS_ROUTES.__ROOT__,
}

function AppRouter() {
  const [tab, setTab] = useState('')
  const history = useHistory()
  const { pathname } = useLocation()
  const { userClientMetadata } = useCurrentUser()

  const handleTabClick = (tabKey) => {
    setTab(tabKey)
    history.push(ROUTES[tabKey])
  }

  const linkMatcher = useCallback(
    (link) => !!matchPath(pathname, { path: link, exact: true }),
    [pathname],
  )

  useEffect(() => {
    const currentPage = Object.entries(ROUTES).find(([, path]) =>
      linkMatcher(path),
    )
    const currentPageKey = currentPage?.[0]
    setTab(KEYS[currentPageKey])
  }, [])

  const { appList } = useAppList()

  useEffect(() => {
    // check if user is coming from one of our web apps because they can come from THERMS.io login, google, etc.
    const referrerFromTHERMSApp = WEB_APPS_LIST.find(({ link }) =>
      document.referrer
        ? new URL(document.referrer).pathname.includes(link)
        : false,
    )

    const isImportantPage = document.location.href.includes(
      MAIL_ROUTES.__ROOT__,
    )

    let declineRedirect = false

    if (isImportantPage) {
      declineRedirect = true
    }

    if (isProd && !referrerFromTHERMSApp && !isImportantPage) {
      ;(function () {
        const redirectToApp = appList.find((app) => app.name !== 'profile')

        if (!redirectToApp) {
          console.info(`User has no app to redirect to from Profile`)

          return
        }

        Alerts.info(
          <div className="flex justify-between">
            Redirecting to {redirectToApp.name}...{' '}
            <Button
              onClick={() => {
                declineRedirect = true
                toastManager.dismiss('redirecting-webapp')
              }}
              subtle
              variant="main"
            >
              Cancel
            </Button>
          </div>,
          {
            autoClose: 1500,
            hideProgressBar: false,
            onClose: () => {
              if (declineRedirect) return

              window.location.href = redirectToApp.link
            },
            showCloseButton: false,
            toastId: 'redirecting-webapp',
          },
        )
      })()
    }
  }, [])

  return (
    <AppLayoutContainer>
      {Array.isArray(appList) && !!appList.length && (
        <div className="space-y-xxs p-base">
          <div className="text-sm font-medium uppercase tracking-widest text-color-subtle">
            App Selection
          </div>
          <HorizontalScroll itemWidth="18rem">
            {appList.map((app) => (
              <AppCard
                icon={app.icon as string}
                name={app.name}
                description={app.description}
                link={app.link}
                key={app.name}
              />
            ))}
          </HorizontalScroll>
        </div>
      )}
      <div className="mx-base">
        <Tabs currentKey={tab} onTabClick={handleTabClick}>
          <Tabs.Tab tabKey={KEYS.INFORMATION} title={PAGE_NAMES.INFORMATION} />
          <Tabs.Tab tabKey={KEYS.PERMISSIONS} title={PAGE_NAMES.PERMISSIONS} />
          <Tabs.Tab tabKey={KEYS.AUDIT_LOGS} title={PAGE_NAMES.AUDIT_LOGS} />
          <Tabs.Tab
            tabKey={KEYS.AUTH_SESSIONS}
            title={PAGE_NAMES.AUTH_SESSIONS}
          />
          <Tabs.Tab
            tabKey={KEYS.MAIL}
            notifications={userClientMetadata.unread.mail}
            title={PAGE_NAMES.MAIL}
          />
          {/* @todo: commented out until implementation is finished  */}
          {/* <Tabs.Tab */}
          {/*   tabKey={KEYS.NOTIFICATIONS} */}
          {/*   title={PAGE_NAMES.NOTIFICATIONS} */}
          {/* /> */}
        </Tabs>
      </div>

      <Switch>
        <Route path={ROUTES.INFORMATION} component={InformationRoutes} />
        <Route path={ROUTES.PERMISSIONS} component={PermissionsRoutes} />
        <Route path={ROUTES.NOTIFICATIONS} component={NotificationsRoutes} />
        <Route path={ROUTES.AUDIT_LOGS} component={AuditLogsRoutes} />
        <Route path={ROUTES.AUTH_SESSIONS} component={AuthSessionsRoutes} />
        <Route path={ROUTES.MAIL} component={MailRoutes} />

        <Redirect to={ROUTES.INFORMATION} />
      </Switch>
    </AppLayoutContainer>
  )
}

export { AppRouter }
