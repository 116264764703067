import React, { useReducer } from 'react'
import { Label, ToggleSwitch } from '@therms/atalaya'

const settings = new Array(25).fill(false)

const reducer = (state, { index, isActive }) => {
  // eslint-disable-next-line no-param-reassign
  state[index] = isActive

  return state
}

function NotificationSettings() {
  const [settingsByIndex, dispatch] = useReducer(reducer, settings, undefined)

  return settings.map((value, index) => (
    <div className="flex justify-between space-y-base" key={value}>
      <Label>Label Name {index}</Label>
      <ToggleSwitch
        name={`field_${index}`}
        onChangeValue={(isActive) => dispatch({ index, isActive })}
        active={settingsByIndex[index]}
      />
    </div>
  ))
}

export { NotificationSettings }
