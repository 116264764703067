import React from 'react'
import { useWindowSize } from '@therms/react-modules'

interface AppLayoutContainerProps {
  children: React.ReactNode
}

function AppLayoutContainer({ children }: AppLayoutContainerProps) {
  const { width } = useWindowSize()

  return (
    <div
      className="h-full overflow-y-auto"
      style={{
        // we set the width explicitly so children don't cause horizontal scroll
        width,
      }}
    >
      {children}
    </div>
  )
}

export { AppLayoutContainer }
