import React from 'react'
import {Route, Switch} from "react-router-dom"
import { AuditLogsIndex } from './screens'

function AuditLogsRoutes() {
    return (
        <Switch>
            <Route component={AuditLogsIndex} />
        </Switch>
    )
}

export { AuditLogsRoutes }
