import React, { useState } from 'react'
import { ActionModal, ActionModalProps } from '@therms/atalaya'
import { RegionName, SelectRegion } from '@therms/react-modules'

interface ChangePrimaryRegionActionModalProps
  extends Omit<ActionModalProps, 'body' | 'onAction' | 'title'> {
  primaryRegionId: string
  onSave: (regionId: string) => void
}

function ChangePrimaryRegionModal({
  onSave,
  primaryRegionId,
  ...actionModalProps
}: ChangePrimaryRegionActionModalProps) {
  const [currentId, setCurrentId] = useState(primaryRegionId)

  return (
    <ActionModal
      actionText="Confirm"
      title="Change Primary Region"
      onAction={() => onSave(currentId)}
      /* eslint-disable-next-line react/jsx-props-no-spreading */
      {...actionModalProps}
      body={
        <div className="space-y-base">
          Your Primary Region is the default Region used when you login to
          THERMS. You can change this to a different Primary Region if you want
          to default to a different Region than{' '}
          <RegionName regionId={primaryRegionId} />.
          <SelectRegion onChangeValue={setCurrentId} value={currentId} />
        </div>
      }
    />
  )
}

export { ChangePrimaryRegionModal }
