// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.editable-field {

  border-radius: var(--border-radius-base);

  padding-right: 3rem;

  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter;

  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter;

  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter, -webkit-backdrop-filter;

  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);

  transition-duration: 150ms;

  background: transparent;
  border: none;
  width: 100%;
}

  .editable-field:focus {
    background: var(--color-input-background);
  }

  .editable-field:hover {
    background: var(--color-input-background);
  }

  .editable-field:disabled {

  background-color: var(--color-neutral-faded);

  color: var(--color-neutral);

  opacity: 0.8;
}

  .editable-field:disabled:hover {
      background: transparent;
    }

  .editable-field:-moz-read-only {
    background: transparent;
    box-shadow: none;
    cursor: auto;
  }

  .editable-field:read-only {
    background: transparent;
    box-shadow: none;
    cursor: auto;
  }
`, "",{"version":3,"sources":["webpack://./src/components/EditableField/index.css"],"names":[],"mappings":"AACE;;EAAA,wCAA+B;;EAA/B,mBAA+B;;EAA/B,gKAA+B;;EAA/B,wJAA+B;;EAA/B,iLAA+B;;EAA/B,wDAA+B;;EAA/B,0BAA+B;;EAE/B,uBAAuB;EACvB,YAAY;EACZ;AAJ+B;;EAM/B;IACE,yCAAyC;EAC3C;;EAEA;IACE,yCAAyC;EAC3C;;EAGE;;EAAA,4CAAqD;;EAArD,2BAAqD;;EAArD;AAAqD;;EAErD;MACE,uBAAuB;IACzB;;EAGF;IACE,uBAAuB;IACvB,gBAAgB;IAChB,YAAY;EACd;;EAJA;IACE,uBAAuB;IACvB,gBAAgB;IAChB,YAAY;EACd","sourcesContent":[".editable-field {\n  @apply rounded pr-12 transition;\n\n  background: transparent;\n  border: none;\n  width: 100%;\n\n  &:focus {\n    background: var(--color-input-background);\n  }\n\n  &:hover {\n    background: var(--color-input-background);\n  }\n\n  &:disabled {\n    @apply bg-neutral-faded text-color-neutral opacity-80;\n\n    &:hover {\n      background: transparent;\n    }\n  }\n\n  &:read-only {\n    background: transparent;\n    box-shadow: none;\n    cursor: auto;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
