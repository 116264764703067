import React, { useMemo } from 'react'
import {
  ACCESS_AND_PERMISSIONS_LABELS,
  Icon,
  If,
  RegionNames,
  RPCDocs,
  useCurrentUser,
  useLocationsAndGroups,
} from '@therms/react-modules'
import { Label, Stack } from '@therms/atalaya'

const { RoleElement } = RPCDocs.Users.GetUserAccessAndPermissions.v1

function PermissionsIndex() {
  const { anp, superAdmin } = useCurrentUser()

  const { data: locationAndGroupsData } = useLocationsAndGroups(
    anp.locations.locationAndGroupIds && {
      idFilter: anp.locations.locationAndGroupIds,
    },
  )

  const roleVisibility = useMemo(() => {
    const { roles } = anp

    if (roles)
      return {
        showAdmin: anp.roles.includes(RoleElement.Admin),
        showOPS: anp.roles.includes(RoleElement.Ops),
        showPortal: anp.roles.includes(RoleElement.Portal),
      }

    return {}
  }, [anp])

  if (superAdmin) {
    return (
      <div className="mt-xxl flex flex-col items-center px-base">
        <Icon name="admin_panel_settings" color="caution" size="xxl" />

        <span className="text-lg text-color-caution">
          You have Super Admin privileges
        </span>

        <span>No permission restrictions apply to you.</span>
      </div>
    )
  }

  if (!anp)
    return (
      <div className="mt-xxl flex flex-col items-center px-base">
        <Icon name="gpp-bad" size="xxl" color="subtle" />
        <div className="mt-base text-center text-lg text-color-subtle">
          No Access or Permissions <br /> to display.
        </div>
      </div>
    )

  return (
    <div className="mt-xs px-base">
      <div className="grid grid-cols-1 gap-x-xs gap-y-base tracking-wide md:grid-cols-2">
        {anp.regions && (
          <Stack>
            <Label>Regions</Label>

            <div>
              {anp.regions.all ? (
                'All Regions'
              ) : (
                <RegionNames regionIds={anp.regions.regionIds} />
              )}
            </div>
          </Stack>
        )}

        {!!anp.locations &&
          (anp.locations.all ? (
            <Stack>
              <Label>Locations</Label>
              <div>All Locations</div>
            </Stack>
          ) : (
            <>
              <Stack>
                <Label>Locations</Label>

                {locationAndGroupsData && (
                  <div>
                    {locationAndGroupsData.locationsAndGroups
                      .filter(({ type }) => type === 'location')
                      .map((location) => location.label)
                      .join(', ')}
                  </div>
                )}
              </Stack>

              <Stack>
                <Label>Location Groups</Label>

                {locationAndGroupsData && (
                  <div>
                    {locationAndGroupsData.locationsAndGroups
                      .filter(({ type }) => type === 'location-group')
                      .map((group) => group.label)
                      .join(', ')}
                  </div>
                )}
              </Stack>
            </>
          ))}
      </div>

      <If truthy={Object.values(roleVisibility).filter((x) => !!x).length}>
        <div className="mt-xl font-bold uppercase tracking-wide">
          <Label>Apps</Label>
        </div>

        <div className="mt-xs border-t border-border" />

        <Stack className="tracking-wide" marginTop="sm" space="xl">
          {roleVisibility.showAdmin && (
            <Stack>
              <Label>Admin</Label>

              <div className="mt-xs">
                <div className="font-light text-color-info">Permissions</div>

                <div className="mt-xxs grid grid-cols-1 gap-x-sm md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
                  {Object.keys(
                    ACCESS_AND_PERMISSIONS_LABELS.ADMIN_PERMISSIONS,
                  ).map((key) => {
                    const checked = anp.role.admin.permissions[key]

                    return (
                      <div
                        className={checked ? '' : `text-color-neutral-faded`}
                      >
                        <Icon
                          name={checked ? 'check' : ''}
                          className="mr-xxs"
                          color="positive"
                          preserveSpace
                        />
                        {ACCESS_AND_PERMISSIONS_LABELS.ADMIN_PERMISSIONS[key]}
                      </div>
                    )
                  })}
                </div>
              </div>
            </Stack>
          )}

          {roleVisibility.showOPS && (
            <Stack>
              <Label>OPS</Label>

              <div className="mt-xs">
                <div className="font-light text-color-info">Permissions</div>

                <div className="mt-xxs grid grid-cols-1 gap-x-sm md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
                  {Object.keys(
                    ACCESS_AND_PERMISSIONS_LABELS.OPS_PERMISSIONS,
                  ).map((key) => {
                    const checked = anp.role.ops.permissions[key]

                    return (
                      <div
                        className={checked ? '' : `text-color-neutral-faded`}
                      >
                        <Icon
                          name={checked ? 'check' : ''}
                          className="mr-xxs"
                          color="positive"
                          preserveSpace
                        />
                        {ACCESS_AND_PERMISSIONS_LABELS.OPS_PERMISSIONS[key]}
                      </div>
                    )
                  })}
                </div>
              </div>
            </Stack>
          )}

          {roleVisibility.showPortal && (
            <Stack>
              <Label>Portal</Label>

              <div className="mt-xs">
                <div className="font-light text-color-info">Access</div>

                <div className="mt-xxs grid grid-cols-1 gap-x-sm md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
                  {Object.keys(ACCESS_AND_PERMISSIONS_LABELS.PORTAL_ACCESS).map(
                    (key) => {
                      const checked = anp.role.portal.access[key]

                      return (
                        <div
                          className={checked ? '' : `text-color-neutral-faded`}
                        >
                          <Icon
                            name={checked ? 'check' : ''}
                            className="mr-xxs"
                            color="positive"
                            preserveSpace
                          />
                          {ACCESS_AND_PERMISSIONS_LABELS.PORTAL_ACCESS[key]}
                        </div>
                      )
                    },
                  )}
                </div>

                <div className="mt-sm font-light text-color-info">
                  Permissions
                </div>

                <div className="mt-xxs grid grid-cols-1 gap-x-sm md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
                  {Object.keys(
                    ACCESS_AND_PERMISSIONS_LABELS.PORTAL_PERMISSIONS,
                  ).map((key) => {
                    const checked = anp.role.portal.permissions[key]

                    return (
                      <div
                        className={checked ? '' : `text-color-neutral-faded`}
                      >
                        <Icon
                          name={checked ? 'check' : ''}
                          className="mr-xxs"
                          color="positive"
                          preserveSpace
                        />
                        {ACCESS_AND_PERMISSIONS_LABELS.PORTAL_PERMISSIONS[key]}
                      </div>
                    )
                  })}
                </div>
              </div>
            </Stack>
          )}
        </Stack>
      </If>
    </div>
  )
}

export { PermissionsIndex }
