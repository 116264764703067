type BUILD_ENV = {
  BUILD_DATE: string
  GOOGLE_MAPS_API_KEY: string
  RPC_HTTP_HOST: string
  RPC_WS_HOST: string
}

declare const BUILD_ENV: BUILD_ENV

export const GLOBALS: BUILD_ENV = {
  BUILD_DATE: BUILD_ENV.BUILD_DATE,
  GOOGLE_MAPS_API_KEY: BUILD_ENV.GOOGLE_MAPS_API_KEY,
  RPC_HTTP_HOST: BUILD_ENV.RPC_HTTP_HOST,
  RPC_WS_HOST: BUILD_ENV.RPC_WS_HOST,
}
