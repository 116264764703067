import React from 'react'
import { Route, Switch } from 'react-router-dom'
import { PermissionsIndex } from './screens'

function PermissionsRoutes() {
  return (
    <Switch>
      <Route component={PermissionsIndex} />
    </Switch>
  )
}

export { PermissionsRoutes }
