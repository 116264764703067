import React from 'react'
import { Route, Switch } from 'react-router-dom'
import { AuthSessionsIndex } from './screens'

function AuthSessionsRoutes() {
  return (
    <Switch>
      <Route component={AuthSessionsIndex} />
    </Switch>
  )
}

export { AuthSessionsRoutes }
