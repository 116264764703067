import React, { useCallback, useState } from 'react'
import { Notification, useUserNotifications } from '@therms/react-modules'

function Notifications() {
  const {
    data: { userNotifications },
  } = useUserNotifications()

  const [readById, setReadById] = useState(
    Object.fromEntries(userNotifications.map(({ id, read }) => [id, read])),
  )

  const handleRead = useCallback(
    (isRead: boolean, notificationId: string) =>
      setReadById({
        ...readById,
        [notificationId]: !isRead,
      }),
    [readById],
  )

  return (
    <div className="space-y-xxl">
      {userNotifications.map((notificationData) => (
        <Notification
          body={notificationData.body}
          date={notificationData.date}
          iconUrl={notificationData.iconUrl}
          important={notificationData.important}
          link={notificationData.link}
          linkTitle={notificationData.linkTitle}
          onReadClick={() =>
            handleRead(readById[notificationData.id], notificationData.id)
          }
          read={readById[notificationData.id]}
          title={notificationData.title}
          key={notificationData.id}
        />
      ))}
    </div>
  )
}

export { Notifications }
